import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';

import RegisterUser from './RegisterUser';
import RegisterForm from './RegisterForm';

import logo from '../../assets/TataTrustsNewLogo.png';
import './Registration.css';
import RegisterSuccess from './RegisterSuccess';

class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }



render() {
return (
    <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <div className="register-box">
              <div class="logo">
                <img src={logo} className="tthomelogo"/>
              </div>
              {/* <h4 className="red-font raleway-light">Centre for Oncopathology </h4> */}

              <div className="register-container-box login-box-inner">
                <div class="row">
                    {/* <div class="col-11 register-heading">
                        <span>Register</span>
                        <span>Already have an account ?</span><Link to="/login"><span>Sign In</span></Link>
                    </div> */}

                   

                <div class="col-12 text-right">
                    <Link to="/" ><div>X</div></Link>
                </div>
                </div>
                    {/* <RegisterUser userSelection={this.handleUserSelection} /> */}
                {/* <RegisterSuccess /> */}

                      {/* code added by Astha */}
                      <div class="col-12 text-center">
                         <span style={{fontSize: '40px'}}>Stay Tuned!</span>
                         <p  style={{fontSize: '20px'}}>This facility is launching soon.</p>
                         <p style= {{fontSize: '20px'}}> Meanwhile kindly contact us through email on infocop@tatacancercare.org or drop a message on our WhatsApp number 8828036476 .</p>
                      </div>
                
              </div>


            </div>
            
          </div>
      </div>
      {/* <div className="privacy-text"><span>Terms</span> | <span>Privacy</span></div> */}
    </div>
);
}
}


export default Registration;